@charset "UTF-8";

@page {
	size: A4;
}

@media print {
	html {
		font-size: 50%;
	}
	body {
		color: #000;
		-webkit-print-color-adjust: exact;
		color-adjust: exact;
	}
	thead {
		display: table-header-group;
	}
	tr,
	img {
		page-break-inside: avoid;
	}
	p {
		orphans: 3;
		widows: 3;
	}
	h2,
	h3 {
		page-break-after: avoid;
	}
	.layout {
		display: block;
	}
	.header__nav,
	.footer__pagetop,
	.footer__fnav {
		display: none;
	}
	.wrapper {
		max-width: 100%;
		width: 100%;
	}
	.header {
		position: static;
		height: auto;
		padding: 0;
		border-top: none;
		border-bottom: none;
	}
	.header__title {
		position: static;
		width: 114px;
		margin-bottom: 10px;
	}
	.footer {
		padding: 0 2.5%;
		background: none;
	}
	.footer__copyright {
		color: #000;
	}
	.content {
		padding-bottom: 40px;

		& > :last-child {
			margin-bottom: 0;
		}
	}
	.bo-page-title {
		margin-bottom: 20px;
	}

	/* コーポレートカラー */
	.navy {
		color: #163357;
	}
	.navy_bg {
		background: #163357;
	}
	.white {
		color: #fff;
	}

	/* プラグイン */
	.owl-stage {
		width: 100% !important;
		transform: none !important;
	}
	.owl-item {
		display: none !important;
		width: 100% !important;
		margin: 0 !important;
		opacity: 1 !important;
	}
	.owl-item.active {
		display: block !important;
	}
}
